body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* * NAVBAR */

.navbar-item {
  text-decoration: none;
}

/* * NavLink Links */
.navlink-item {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: #1976d2;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-decoration-color: rgba(25, 118, 210, 0.4);
}


/* * ONBOARDING ITEM */
.onboarding-item-text {
  text-decoration: none;
  color: #1976d2;
}


.img-ine {
  max-width: 40%;
  height: auto;
  padding: 0;
  margin: 0;
}

.img-ine-modal {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.text-decoration-none {
  text-decoration: none;
  color: white;
}

.item-private-layout {
  text-decoration: none;
  color: #000000DE;
}